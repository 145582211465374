<!--
 * @Description: 填写发票信息 fillInvoice
 * @Author: yanxiao
 * @Github:
 * @Date: 2021-06-07 11:29:48
 * @LastEditors: zhoucheng
-->
<template>
  <div class='body'>
    <van-nav-bar title="填写发票信息"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-row class="up style">
      <van-row style="padding-top:25px;padding-bottom:10px">
        <span style="margin-left:15px;font-weight: bold;">发票详情</span>
      </van-row>
      <van-field name="radio"
                 label="抬头类型">
        <template #input>
          <van-radio-group v-model="comPanyRadio"
                           direction="horizontal"
                           @change="handleChangeRadio">
            <van-radio icon-size="13"
                       name="1">企业单位</van-radio>
            <van-radio icon-size="13"
                       name="0">个人/非企业单位</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-row v-if="showCompany===1">
        <van-field v-model="invoiceTitle"
                   label="发票抬头"
                   placeholder="填写发票抬头" />
        <van-field v-model="invoiceValue"
                   label="发票税号"
                   placeholder="填写纳税人识别号" />
        <van-cell title="发票内容"
                  value="停车服务费" />
        <van-cell title="发票金额"
                  :value="invoiceMoney" />
        <van-cell title="更多信息"
                  value="非必填"
                  is-link />
      </van-row>
    </van-row>
    <van-row v-if="showCompany===0">
      <van-field v-model="selfName"
                 label="名称"
                 placeholder="请填写名称" />
    </van-row>
    <van-row class="middle style">
      <van-row style="padding-top:25px;padding-bottom:10px">
        <span style="margin-left:15px;font-weight: bold;">接收方式</span>
      </van-row>
      <van-field v-model="userEmail"
                 label="电子邮箱"
                 placeholder="填写邮箱账号" />
    </van-row>
    <van-row class="down style">
      <van-button class="button"
                  @click="handleClickOnSubmit">提 交</van-button>
    </van-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      comPanyRadio: '1', // 抬头选择 1为公司 0为个人
      showCompany: 1, // 展示企业或个人 1为企业 0为个人
      invoiceMoney: 0, // 开票金额
      selfName: '', // 个人开票姓名
      userEmail: '', // 电子邮箱
      invoiceTitle: '', // 发票抬头
      invoiceValue: '', // 发票税号
      paymentSequenceList: []// 开票流水号数组
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    // 开票金额
    this.invoiceMoney = this.$route.query.invoiceMoney + '元'
    // 选择开票流水号
    this.paymentSequenceList = this.$route.query.paymentSequenceList
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 点击提交
    handleClickOnSubmit () {
      if (this.comPanyRadio === '1') {
        // 企业
        if (this.invoiceValue.length !== 15 &&
          this.invoiceValue.length !== 17 &&
          this.invoiceValue.length !== 18 &&
          this.invoiceValue.length !== 20) {
          this.$toast('请输入正确的企业税号！')
        } else {
          const info = {
            paymentSequences: this.paymentSequenceList,
            title: Number(this.comPanyRadio),
            taxnum: this.invoiceValue,
            buyerName: this.invoiceTitle,
            email: this.userEmail,
            address: '',
            telephone: '',
            account: ''
          }
          this.$parkInvoice.invoice(info).then(res => {
            // const list = JSON.parse(res.resultEntity)
            // list.list[0].c_fpqqlsh
            // list.list[0].c_msg
            // console.log(list, 'listlistlistlistlistlist')
            this.$router.push('/invoiceHistory')
          })
        }
      } else {
        // 个人
        const info = {
          paymentSequences: this.paymentSequenceList,
          title: Number(this.comPanyRadio),
          buyerName: this.selfName,
          email: this.userEmail
        }
        this.$parkInvoice.invoice(info).then(() => {
          this.$router.push('/invoiceHistory')
        })
      }
    },
    // 抬头类型切换
    handleChangeRadio () {
      if (this.comPanyRadio === '1') {
        this.showCompany = 1
      } else {
        this.showCompany = 0
      }
    },
    // 顶部返回
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类

.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .up {
    // height: 421px;
    margin-top: 12px;

    /deep/.van-radio__label {
      color: #909090 !important;
    }
    .van-cell {
      padding: 15px 16px;
    }
  }
  .middle {
    margin-top: 12px;
    height: 114px;
  }
  .down {
    height: 50px;
    position: absolute;
    bottom: 0;
    .button {
      width: 93%;
      height: 40px;
      margin: 5px 3.5%;
      border-radius: 5px;
      background-color: #19a9fc;
      color: white;
    }
  }
}
.style {
  width: 100%;
  background-color: #ffffff;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 400;
  color: #333333;
}
</style>
